<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons-vue'

const store = useStore()
const router = useRouter()
const route = useRoute()
const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])
const authStatus = computed(() => store.getters['auth/authStatus'])
const user = computed(() => store.getters['auth/user'])
const loading = ref(false)

onMounted(async () => {
  if (isAuthenticated.value && !authStatus.value) {
    await store.dispatch('auth/setUser').catch((e) => {
      store.dispatch('auth/clearUser')
      router.push({ name: 'login' })
    })
  } else if (!isAuthenticated.value || !authStatus.value) {
    await router.push({ name: 'login' })
  }
})

const isMenuItemActive = (matchArray) => {
  route.matched.find((r) => {
    return matchArray.includes(r.name)
  })
  return !!route.matched.find((r) => matchArray.includes(r.name))
}

const performAction = async (action) => {
  loading.value = true
  await store.dispatch(action)
  loading.value = false
}

const loginAsStaticMediaUser = async () => {
  await performAction('auth/loginAsStaticMediaUser')
}

const loginAsDemoUser = async () => {
  await performAction('auth/loginAsDemoUser')
}

const loginAsTemplateConstructorAdmin = async () => {
  await performAction('auth/loginAsTemplateConstructorAdmin')
}

const logOut = async () => {
  await performAction('auth/logOut')
}

const handleMenuItemClick = (e) => {
  e.item.action()
}

const loginAsMenuItems = [
  {
    key: 'grp',
    label: 'Login As:',
    type: 'group',
    children: [
      {
        key: '1',
        label: 'Demo User',
        action: loginAsDemoUser
      },
      {
        key: '2',
        label: 'Template Constructor Admin',
        action: loginAsTemplateConstructorAdmin
      },
      {
        key: '3',
        label: 'Static Media User',
        action: loginAsStaticMediaUser
      }
    ]
  }
]

</script>

<template>
  <a-layout
    v-if="authStatus"
    style="height: 100%; min-height: 100%;"
  >
    <a-spin
      tip="Loading..."
      :spinning="loading"
    >
      <a-layout-header class="header">
        <a-layout>
          <a-layout-content
            style="display: flex; height: inherit;"
            class="header-wrapper"
          >
            <a-menu
              v-if="user"
              theme="dark"
              mode="horizontal"
              class="header-menu"
              :style="{ lineHeight: '64px', flex: 1 }"
            >
              <a-menu-item
                key="users"
                :class="{'ant-menu-item-selected': isMenuItemActive(['users','user'])}"
              >
                <router-link :to="{name:'users'}">
                  Users
                </router-link>
              </a-menu-item>
              <a-menu-item
                key="dashboard"
                :class="{'ant-menu-item-selected': isMenuItemActive(['general'])}"
              >
                <router-link :to="{name:'general'}">
                  Stats
                </router-link>
              </a-menu-item>
              <a-menu-item
                key="apiStatus"
                :class="{'ant-menu-item-selected': isMenuItemActive(['apiStatus'])}"
              >
                <router-link :to="{name:'apiStatus'}">
                  API Status
                </router-link>
              </a-menu-item>
              <a-menu-item
                key="admins"
                :class="{'ant-menu-item-selected': isMenuItemActive(['admins'])}"
              >
                <router-link :to="{name:'admins'}">
                  Admins
                </router-link>
              </a-menu-item>
              <a-menu-item
                key="settings"
                :class="{'ant-menu-item-selected': isMenuItemActive(['settings'])}"
              >
                <router-link :to="{name:'settings'}">
                  Settings
                </router-link>
              </a-menu-item>
            </a-menu>
            <div class="actions">
              <a-dropdown placement="bottom">
                <UserOutlined style="color:#fff" />
                <template #overlay>
                  <a-menu
                    :items="loginAsMenuItems"
                    @click="handleMenuItemClick"
                  />
                </template>
              </a-dropdown>
              <a
                style="display: contents"
                @click="logOut"
              >
                <a-tooltip title="Logout">
                  <LogoutOutlined style="color:#fff" />
                </a-tooltip>
              </a>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout-header>
      <a-layout>
        <a-layout>
          <a-layout-content
            :style="{ background: '#fff', margin: 0 }"
          >
            <router-view v-slot="{ Component }">
              <transition
                name="fade"
                mode="out-in"
              >
                <component :is="Component" />
              </transition>
            </router-view>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-spin>
  </a-layout>
</template>

<style lang="less">
@import "../styles/variables.less";

.ant-layout-header.header {
  padding: 0;
  .header-wrapper {
    background-color: @layout-header-background;
    .header-menu {
      background-color: transparent;
    }
  }
  .actions {
    padding: 0 24px;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    .anticon {
      font-size: 20px;
    }
    color: @text-color-secondary;
    &:hover {
      color: @text-color;
    }
  }
}
.ant-spin-nested-loading {
  height: inherit;
  .ant-spin-container {
    height: inherit;
    display: flex;
    flex-direction: column;
  }
}

</style>
