import { gql } from 'graphql-tag'
import {
  ADMIN_NOTE,
  PLAYLIST_EXTENDED,
  SLIDE_BASE,
  WIDGET,
  WORKSPACE_FEATURES,
  WORKSPACE_SUBSCRIPTION
} from '@/graphql/fragments'

// AUTH

export const LIST_SESSIONS = gql`
  query listMySessions {
    listMySessions {
      sessionId
      ip
      country
      isCurrent
      createdAt
      updatedAt
    }
  }
`

// STATS
export const GET_STATS = gql`
  query {
    getStats {
      users {
        totalCount
        usersCreatedByV1MigrationCount
        usersCreatedByV2GoogleSsoLoginCount
        usersCreatedByV2OidcSsoLoginCount
        usersCreatedByRegistrationCount
      }
      workspaces {
        totalCount
        paidCount
        pastDueCount
        inactiveCount
        demoCount
      }
      enterprizeWorkspaces {
        totalCount
        paidCount
        pastDueCount
        inactiveCount
        demoCount
      }
      growthWorkspaces {
        totalCount
        paidCount
        pastDueCount
        inactiveCount
        demoCount
      }
      devices {
        totalCount
        onlineDevicesCount
      }
      stripe {
        currentWeekUpcomingInvoicesAmount
        currentMonthUpcomingInvoicesAmount
        currentYearUpcomingInvoicesAmount
        currentWeekToBePaidAverageAmount
        currentMonthToBePaidAverageAmount
        currentYearToBePaidAverageAmount
        pastDueAmount
        currentMonthNewSubscriptionsAmount
        currentMonthPaidInvoicesAmount
        currentWeekNewSubscriptionsAmount
        currentWeekPaidInvoicesAmount
        currentYearNewSubscriptionsAmount
        currentYearPaidInvoicesAmount
      }
    }
  }
`

export const GET_TRACKING_QUANTITIES = gql`
  query {
    getTrackingQuantities {
      currentPeriodEnd
      currentPeriodStart
      description
      maxValue
      name
      resetCronExpression
      value
    }
  }
`

export const GET_THIRD_PARTY_STATUSES = gql`
  query {
    get3DPartyApiStatuses {
      googleReviewsApiOk
      rapidApiFunFactsApiOk
      rapidApiHoroscopesApiOk
      rapidApiPublicHolidaysApiOk
      rapidApiTastyApiOk
      rapidApiTedTalksApiOk
      tickerApiOk
      tripAdvisorApiOk
      covidStatsApiOk
      dropboxOauthApiOk
      googleOauthApiOk
      instagramOauthApiOk
      microsoftOauthApiOk
      newsApiOk
      twitterOauthApiOk
      weatherApiOk
      yelpApiOk
    }
  }
`

// PROFILE

export const LOGGED_IN_USER = gql`
  query {
    getMyProfile {
      id
      username
      name
      is2faSet
      isPasswordSet
      createdAt
      updatedAt
    }
  }
`

// USERS

export const LIST_USERS = gql`
  query listUsers($filters: ListUsersFiltersInput $pagination: OffsetPaginationInput ) {
    listUsers(filters: $filters pagination: $pagination) {
      pagination {
        limit
        offset
        total
      }
      data {
        id
        ...on UserModel {
          email
          emailVerified
          firstName
          lastName
          isPasswordSet
          phone
          companyName
          companySize
          language
          createdAt
          updatedAt
        }
        ...on DeletedUserModel {
          deletedAt
        }
      }
    }
  }
`

export const LIST_ADMINS = gql`
  query listAdmins($filters: ListAdminsFiltersInput $pagination: OffsetPaginationInput ) {
    listAdmins(filters: $filters pagination: $pagination) {
      pagination {
        limit
        offset
        total
      }
      data {
        createdAt
        id
        is2faSet
        isPasswordSet
        name
        updatedAt
        username
      }
    }
  }
`

export const GET_USER_BY_ID = gql`
  query getUserById($id: String!) {
    getUserById(id: $id) {
      id
      ...on UserModel {
        features {
          ownedWorkspacesLimit
        }
        email
        emailVerified
        firstName
        lastName
        isPasswordSet
        phone
        companyName
        companyIndustry
        companySize
        language
        createdAt
        userCreatedBy
        updatedAt
      }
      ...on DeletedUserModel {
        deletedAt
      }
    }
  }
`

export const GET_DEMO_USER = gql`
  query getDemoUser {
    getDemoUser {
      id
    }
  }
`
export const GET_STATIC_MEDIA_USER = gql`
  query getStaticMediaUser {
    getStaticMediaUser {
      id
    }
  }
`
export const GET_PRE_CREATED_WIDGETS_USER = gql`
  query getPreCreatedWidgetsUser {
    getPreCreatedWidgetsUser {
      id
    }
  }
`

// GROUPS

export const GET_AVAILABLE_GROUPS = gql`
  query listAllGroups ($filters: ListGroupsFiltersInput!){
    listAllGroups(filters: $filters) {
      id
      ...on StandardGroupModel {
        externalId
        name
        parentGroupId
      }
      ...on SmartGroupModel {
        name
      }
    }
  }
`

export const GET_GROUP_BY_ID = gql`
  query getGroupById ($id: String!) {
    getGroupById(
      id: $id
    ) {
      id
      name
      timezone
      createdAt
      updatedAt
      workspaceId
      parentGroupId
      authorIdentityId
      devices {
        id
        name
        createdAt
        updatedAt
        groupId
      }
      schedule {
        id
        createdAt
        updatedAt
        groupId
        authorIdentityId
      }
      playlists {
        id
        version
        isPublished
        paused
        name
        createdAt
        updatedAt
        groupId
        authorIdentityId
        layout {
          type
          zones {
            id
            slides {
              id
              duration
              paused
              widgetId
            }
          }
        }
      }
    }
  }
`

// WORKSPACES

export const GET_USER_WORKSPACES = gql`
  ${WORKSPACE_FEATURES}
  query listWorkspaceMemberRolesByUserId($userId: String!) {
    listWorkspaceMemberRolesByUserId(userId: $userId) {
      role
      restrictions {
        groupIds
        playlistIds
      }
      createdAt
      updatedAt
      workspace {
        id
        name
        createdAt
        updatedAt
        frozen
        features {
          ...workspaceFeatures
        }
        deviceInitializationToken
        settings {
          hideWatermark
          useCustomWatermark
          emergencyFeedUrl
          emergencyFeedEnabled
          whitelabelAccentColor
          demoStorageSizeLimit
        }
        billingInfo {
          billingInterval
          freeDevicesQuantity
          billingDevicesQuantity
          enterprize
          tier
          tierGroup
          stripeCustomPriceId
          stripeGrowthDevicesQuantityLimit
        }
        frozen
      }
    }
  }
`

export const GET_USER_WORKSPACES_SHORT = gql`
  query listWorkspaceMemberRolesByUserId($userId: String!) {
    listWorkspaceMemberRolesByUserId(userId: $userId) {
      role
      workspace {
        id
        name
      }
    }
  }
`

export const GET_WORKSPACE_BY_ID = gql`
  ${WORKSPACE_FEATURES}
  query getWorkspaceById($id: String!) {
    getWorkspaceById(id: $id) {
      id
      name
      activeCoupons {
        activatedAt
        couponType
        deactivationAt
        freeDevicesCount
      }
      createdAt
      updatedAt
      frozen
      frozenReason
      features {
        ...workspaceFeatures
      }
      deviceInitializationToken
      settings {
        hideWatermark
        useCustomWatermark
        emergencyFeedUrl
        emergencyFeedEnabled
        whitelabelAccentColor
        demoStorageSizeLimit
      }
      billingInfo {
        billingInterval
        freeDevicesQuantity
        billingDevicesQuantity
        enterprize
        status
        tier
        tierGroup
        stripeCustomPriceId
        stripeGrowthDevicesQuantityLimit
      }
      frozen
    }
  }
`

export const GET_WORKSPACE_DEVICES_COUNT = gql`
  query countDevicesByWorkspaceId($workspaceId: String!) {
    countDevicesByWorkspaceId(workspaceId: $workspaceId)
  }
`

export const GET_WORKSPACE_MEMBERS = gql`
  query listWorkspaceMemberRolesByWorkspaceId($workspaceId: String!) {
    listWorkspaceMemberRolesByWorkspaceId(workspaceId: $workspaceId) {
      userId
      role
      restrictions {
        groupIds
        playlistIds
      }
      createdAt
      updatedAt
      user {
        id
        ...on UserModel {
          email
          emailVerified
          firstName
          lastName
        }
      }
    }
  }
`

export const LIST_STRIPE_PRICES = gql`
  query listStripePrices($params: StripeListPricesParams) {
    listStripePrices(params: $params) {
      data {
        id
        active
        billing_scheme
        created
        currency
        custom_unit_amount {
          maximum
          minimum
          preset
        }
        lookup_key
        nickname
        product
        recurring {
          interval
          interval_count
          trial_period_days
        }
        tiers {
          flat_amount_decimal
          unit_amount_decimal
          up_to
        }
        tiers_mode
        unit_amount_decimal
        stripeDashboardUrl
      }
      has_more
    }
  }
`

export const GET_WORKSPACE_SUBSCRIPTION = gql`
  ${WORKSPACE_SUBSCRIPTION}
  query getWorkspaceSubscription($workspaceId: String!) {
    getWorkspaceSubscription(workspaceId: $workspaceId) {
      ...subscription
    }
  }
`

export const GET_WORKSPACE_CUSTOMER = gql`
  query getWorkspaceCustomer($workspaceId: String!) {
    getWorkspaceCustomer(workspaceId: $workspaceId) {
      id
      balance
      stripeDashboardUrl
    }
  }
`

// PLAYLISTS

export const LIST_PLAYLISTS = gql`
  ${PLAYLIST_EXTENDED}
  ${SLIDE_BASE}
  ${WIDGET}
  query listPlaylists($filters: ListPlaylistsFiltersInput, $pagination: OffsetPaginationInput) {
    listPlaylists(filters: $filters pagination:$pagination) {
      pagination {
        limit
        offset
        total
      }
      data {
        ...playlistExtended
        layout {
          type
          zones {
            id
            slides {
              ...slideBase
              widget {
                ...widget
              }
            }
          }
        }
      }
    }
  }
`

export const LIST_PLAYLIST_VERSIONS = gql`
  ${PLAYLIST_EXTENDED}
  ${SLIDE_BASE}
  ${WIDGET}
  query getPlaylistVersions($playlistId: String!, $pagination: OffsetPaginationInput!) {
    getPlaylistVersions(playlistId: $playlistId pagination:$pagination) {
      pagination {
        limit
        offset
        total
      }
      data {
        ...playlistExtended
        layout {
          type
          zones {
            id
            slides {
              ...slideBase
              widget {
                ...widget
              }
            }
          }
        }
      }
    }
  }
`

export const LIST_DEVICES = gql`
  query listDevices($filters: ListDevicesFiltersInput, $pagination: OffsetPaginationInput) {
    listDevices(filters: $filters pagination:$pagination) {
      pagination {
        limit
        offset
        total
      }
      data {
        id
        name
        initialTimezone
        timezone
        orientation
        lastActivityAt
        externalId
        immutable
        createdAt
        updatedAt
        groupId
        appVersion
        features {
          useWidgetWebsiteWebview
        }
        hardwareInfo {
          tvos
        }
      }
    }
  }
`

export const LIST_WORKSPACE_DEVICES = gql`
  query getDevicesByWorkspaceId($workspaceId: String!) {
    getDevicesByWorkspaceId(workspaceId: $workspaceId) {
      id
      name
      initialTimezone
      timezone
      orientation
      lastActivityAt
      externalId
      immutable
      createdAt
      updatedAt
      groupId
      appVersion
      features {
        useWidgetWebsiteWebview
      }
      hardwareInfo {
        tvos
      }
    }
  }
`

export const GET_DEVICES_BY_GROUP_ID = gql`
  query getDevicesByGroupId($groupId: String!) {
    getDevicesByGroupId(groupId: $groupId) {
      id
      name
      initialTimezone
      timezone
      orientation
      lastActivityAt
      externalId
      immutable
      createdAt
      updatedAt
      groupId
      appVersion
      features {
        useWidgetWebsiteWebview
      }
      hardwareInfo {
        tvos
      }
    }
  }
`


export const GET_DEVICE_BY_ID = gql`
  query getDeviceById($id: String!) {
    getDeviceById(id: $id) {
      id
      name
      createdAt
      updatedAt
      groupId
    }
  }
`

// NOTES

export const LIST_ADMIN_NOTES = gql`
  ${ADMIN_NOTE}
  query listAdminNotes($filters: ListAdminNotesFiltersInput, $pagination: OffsetPaginationInput!) {
    listAdminNotes(filters: $filters pagination: $pagination) {
      pagination {
        limit
        offset
        total
      }
      data {
        ...adminNote
      }
    }
  }
`
